/**
 * @typedef {Object} SuperMapLatLng
 * @property {Number} lat
 * @property {Number} lng
 */

/**
 * @typedef {Object} SuperMapInterventionRadius
 * @property {Number} value
 * @property {{ fill: { color: String, opacity: Number }, stroke: { color: String, opacity: Number, weight: Number } }}  circleStyles
 */

/**
 * @typedef {Object} SuperMapConfiguration
 * @property {Array<{ formattedAddress: String, position: SuperMapLatLng }>} locations
 * @property {Object|null} styles
 * @property {SuperMapLatLng|null} center
 * @property {Number} zoom
 * @property {String|null} markerIcon
 * @property {SuperMapInterventionRadius|null} interventionRadius
 */

import { addAppleMapCircle, createAppleMapMarker, initAppleMap, initAppleMapKit } from './apple-map-kit';

/**
 * @param $superMap
 * @return {SuperMapConfiguration}
 */
const getConfig = ($superMap) => {
  const config = JSON.parse($superMap.dataset.configuration);

  if (config.center !== null) {
    config.center.lat = parseFloat(config.center.lat);
    config.center.lng = parseFloat(config.center.lng);
  }

  if (config.interventionRadius !== null) {
    config.interventionRadius.value = parseFloat(config.interventionRadius.value) * 1000;
  }

  config.locations = config.locations.map((location) => {
    return {
      ...location,
      position: {
        ...(location.position || {}),
        lat: parseFloat(location.position.lat),
        lng: parseFloat(location.position.lng),
      },
    };
  });

  config.styles = JSON.parse(config.styles);

  return config;
};

export class SuperMap {
  constructor($superMap) {
    this.$superMap = $superMap;
    this.config = getConfig(this.$superMap);

    if (process.env.NODE_ENV === 'development') {
      // eslint-disable-next-line no-console
      console.log('SuperMap', {
        $superMap: this.$superMap,
        config: this.config,
      });
    }

    initAppleMapKit(() => {
      this.init();
    });
  }

  /**
   * @private
   */
  init() {
    this.map = initAppleMap(this.$superMap, {
      zoom: this.config.zoom,
      latCenter: this.config.center.lat,
      lngCenter: this.config.center.lng,
    });

    this.drawMarkers();
    this.drawInterventionRadius();
  }

  /**
   * @private
   */
  drawMarkers() {
    this.config.locations.forEach((location) => {
      createAppleMapMarker(this.map, {
        lat: location.position.lat,
        lng: location.position.lng,
        icon: this.config.markerIcon,
        callout: {
          calloutContentForAnnotation() {
            const element = document.createElement('div');
            element.innerHTML = location.formattedAddress;
            return element;
          },
        },
      });
    });
  }

  /**
   * @private
   */
  drawInterventionRadius() {
    if (this.config.interventionRadius === null) {
      return;
    }

    const { value, circleStyles } = this.config.interventionRadius;
    const { stroke, fill } = circleStyles;

    addAppleMapCircle(this.map, {
      lat: this.config.center.lat,
      lng: this.config.center.lng,
      radius: value,
      strokeColor: stroke.color,
      strokeOpacity: stroke.opacity,
      strokeWeight: stroke.weight,
      fillColor: fill.color,
      fillOpacity: fill.opacity,
    });
  }
}
